import React, { useState, useEffect } from 'react';
import * as API from '../../../app/API';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useTheme } from '@material-ui/core/styles';
import PaymentIcon from '@material-ui/icons/Payment';
import { makeGlobalStyles } from '../../../styles/Styles';
import { fieldError, notifyFormErrors, DepField, requiredErr, defProps, hideLoader, showLoader, logo, disclaimer, emailRegex } from '../../../app/globals';
import { useForm } from 'react-hook-form';
import HorizontalStepper from '../../utilities/Stepper/Horizontal';
import VerticalStepper from '../../utilities/Stepper/Vertical';
import { TextField, Typography, CardMedia, Checkbox, InputAdornment, Button, Container } from '@material-ui/core';
import NotFoundPage from '../../utilities/NotFoundPage';
import AsyncAutocomplete from '../../utilities/AsyncAutocomplete';
import MuiDatePicker from '../../utilities/MuiDatePicker';
import MuiSelect from '../../utilities/MuiSelect';
import { makeNotify } from '../../utilities/Notify';
import { isObject, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { setStepAction, setStepOk, setExternalManagement as setExternalManagementSlice } from '../../../app/store/slices';
import { useDispatch } from 'react-redux';

function StudentForm(props) {
    const classes = makeGlobalStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const urlParams = props.match.params;
    const { register, handleSubmit, setValue, getValues, watch, control, errors, formState: { isSubmitting } } = useForm({ shouldUnregister: false });
    // const { register, handleSubmit, setValue, getValues, watch, control, errors, formState: { isSubmitting } } = useForm({
    //     shouldUnregister: false,
    //     defaultValues: {
    //         visa_entries: "1",
    //         passport_authority: "Government",
    //         passport_country_short: "IT",
    //         visa: { id: 1, name: "EU Citizen (NO Visa)" },
    //         visa_days: "343",
    //         visa_consulate: "Italy",
    //         visa_number: "35674324",
    //         visa_start: "2020-07-8, 00:00:00",
    //         visa_end: "2020-07-8, 00:00:00",
    //         birth_city: "Asunción",
    //         birth_country: "Paraguay",
    //         birth_country_code: "Z610",
    //         birth_date: "2020-07-08",
    //         birth_state: "Asunción",
    //         email: "tommaso.meli@dotflorence.com",
    //         retype_email: "tommaso.meli@dotflorence.com",
    //         first_name: "Tommaso",
    //         gender: "M",
    //         last_name: "Meli",
    //         middle_name: "DOT",
    //         nationality_country_code: "Z700",
    //         passport_end: "2020-07-08",
    //         passport_number: "AB98999",
    //         passport_start: "2020-07-08",
    //         payment_method: "",
    //         residence_address: "Via viuzzo 12",
    //         residence_city: "Saint Petersburg",
    //         residence_country: "Russia",
    //         // residence_country_code: "Z154",
    //         residence_state: "St.-Petersburg",
    //         residence_zip: "4524562",
    //         student_service_id: 1,
    //         student_status_id: "3",
    //         student_visa_id: 1
    //     }
    // });
    const visaField = watch("visa");
    const fieldProps = { ...defProps, className: classes.stepFormField, defaultValue: '', InputLabelProps: {}, onKeyPress: e => e.keyCode !== 13 }
    const fix = <></>
    // State
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serviceData, setServiceData] = useState({});
    const [notFound, setNotFound] = useState(() => <></>);
    const [submitCompleted, setSubmitCompleted] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const [validUrl, setValidUrl] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [visaRequired, setVisaRequired] = useState(true);
    const [onlinePayment, setOnlinePayment] = useState(false);
    const [externalManagement, setExternalManagement] = useState(false);
    // Steps
    const getSteps = () => ['Introduction', { title: 'Personal Information', text: 'Enter your Personal Information' }, { title: 'Contact', text: 'Enter a valid e-mail address' }, { title: 'Birth', text: 'Enter your Birth Information' }, { title: 'Residence', text: 'Enter your HOME COUNTRY Address' }, { title: 'Passport', text: 'Enter your Passport Information' }, { title: 'Visa', text: 'Enter your Visa Information' }];
    const [steps] = useState(getSteps().map(x => x.text ? x.text : x));
    const changeStep = (index, action) => {
        if (!agreement && makeNotify("Accept the agreement to continue")) return false;
        index ? handleSubmit(data => data)() : dispatch(setStepOk(true));
        // action === 'next' && index ? handleSubmit(data => data)() : dispatch(setStepOk(true));
        console.log(index)
        dispatch(setExternalManagementSlice(index >= 2 && externalManagement))
        dispatch(setStepAction(action))
    }
    useEffect(() => {
        // console.log(visaField);
        if (visaField?.id === 1 || visaField?.id === 5) {
            if (visaRequired) setVisaRequired(false);
            setValue("visa_days", 90);
        } else if (!visaRequired) setVisaRequired(true);
        // eslint-disable-next-line
    }, [visaField])
    useEffect(() => {
        if (!isEmpty(errors)) { dispatch(setStepOk(false)); notifyFormErrors(Object.values(errors)) }
        else dispatch(setStepOk(true));
    }, [errors, dispatch])
    const StepperHeader = () => {
        return (
            <div className={classes.stepperHeader}>
                <CardMedia className={classes.stepperLogo} image={logo} title="logo" />
                <h3>{serviceData.partner.name} - Student Registration form</h3>
            </div>
        )
    }
    // useEffect(() => {
    //     console.log(formState)
    // }, [formState]);
    // Submit
    const onStudentSubmit = async () => {
        showLoader();
        try {
            setIsSubmitted(true)
            let fields = getValues();
            // console.log(fields);
            if (externalManagement)
                fields["student_status_id"] = 8
            let formData = {};
            for (var [key, field] of Object.entries(fields)) {
                if (key === 'retype_email') continue;
                if (key === 'visa') key = 'student_visa_id';
                if (key === 'passport_country_short') field = field.iso;
                if (key === 'residence_city' && isObject(field)) {
                    formData.residence_country_code = (await API.getRequest(`nazioniiso/${field.countryCode}`))?.code;
                    // if (formData.residence_country_code) formData.residence_country_code = 'IT';
                    field = field.toponymName;
                }
                if (key === 'birth_city' && isObject(field)) {
                    formData.birth_country_code = (await API.getRequest(`nazioniiso/${field.countryCode}`))?.code;
                    // if (formData.birth_country_code) formData.birth_country_code = 'IT';
                    field = field.toponymName;
                }
                if (isObject(field)) field = field.id ? field.id : field.code;
                formData[key] = field;
            }
            if (!formData.residence_country_code)
                formData.residence_country_code = "Z700";
            // console.log(formData);
            let saved = await API.postRequest(`studentform`, formData);
            console.log(saved)
            if (saved.ok) {
                setSubmitCompleted(true);
                setSubmitData(saved);
                // console.log(saved);
                makeNotify("Submit completed");
            } else makeNotify(`Error ${saved.status}. Try again`);
        } catch (error) {
            console.error(error)
            setIsSubmitted(false)
        }
        hideLoader();

    }
    // Hooks
    useEffect(() => {
        (async () => {
            let data = await API.getRequest(`serviceform/${urlParams.partner}/${urlParams.link}`);
            setOnlinePayment(data.online_payment);
            if (isObject(data) && data.link_enabled) {
                setServiceData(data);
                // Defaults
                Object.entries({ visa_entries: "1", passport_authority: "Government", payment_method: "", student_service_id: data.id, student_status_id: "3" })
                    .map(x => setValue(x[0], x[1]))
                setValidUrl(true);
            } else setNotFound(() => <NotFoundPage />);
            hideLoader();
        })();
        return () => showLoader();
        // eslint-disable-next-line
    }, []);
    // Fields
    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <div>
                            <h4 className={classes.w_100}>{steps[step]}</h4>
                            <Typography>
                                {/* Document Assistance Program Disclaimer: This Disclaimer and Privacy Policy was last updated <span style={{ color: theme.palette.primary.main }}>January 1<sup>st</sup>, {new Date().getFullYear()}</span>
                                <br />
                                <a href={disclaimer} target="_blank" rel="noopener noreferrer">CLICK HERE TO DOWNLOAD THE DISCLAIMER</a>
                                <br /> */}
                                Document Assistance Program Disclaimer: <a style={{ "text-decoration": "underline" }} href={disclaimer} target="_blank" rel="noopener noreferrer">This Disclaimer</a> and <a style={{ "text-decoration": "underline" }} href="https://italy4you.org/img/privacy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> was last updated <span style={{ color: theme.palette.primary.main }}>January 1<sup>st</sup>, {new Date().getFullYear()}</span>
                                <br />
                                <small>By clicking <i>"UNDERSTOOD, AGREED AND ACCEPTED"</i> below, I am attaching my electronic signature and agreeing that I have read, understood, and accept the Disclaimer and Privacy Policy outlined above. I understand that if I do not agree to the terms of the Disclaimer and Privacy Policy outlined above I should close the window, and discontinue my order.</small>
                            </Typography>
                            <div className={classes.w_100}>
                                <small><b>UNDERSTOOD, AGREED AND ACCEPTED</b></small>
                                <Checkbox checked={agreement} onChange={(e) => setAgreement(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            {fix}{fix}
                            <TextField {...fieldProps} id="first_name" name="first_name" label='First Name' inputRef={register({ required: requiredErr })} {...fieldError('first_name', errors)} required />
                            <TextField {...fieldProps} id="middle_name" name="middle_name" label='Middle Name' inputRef={register} />
                            <TextField {...fieldProps} id="last_name" name="last_name" label='Last Name' inputRef={register({ required: requiredErr })} {...fieldError('last_name', errors)} required />
                            <MuiSelect {...fieldProps} id="gender" name="gender" label="Gender" control={control} rules={{ required: true }} options={[{ value: "M", label: "Male" }, { value: "F", label: "Female" }]} {...fieldError('gender', errors)} required />
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            <TextField {...fieldProps} id="email" name="email" label='Email' {...fieldError('email', errors)} required inputRef={register({ required: requiredErr, pattern: { value: emailRegex, message: 'Invalid email' } })} />
                            <TextField {...fieldProps} id="retype_email" name="retype_email" label='Email confirm' {...fieldError('retype_email', errors)} required inputRef={register({ required: requiredErr, validate: value => value === watch('email') || "Emails don't match." })} />
                            <small><b>*Please Note: </b>AOL and some other email accounts will send {process.env.REACT_APP_TITLE} correspondence into the "Junkmail" folder, so please check this area of your email account for messages from us, as this can delay our services.</small>
                            {
                                !!serviceData.externalManagement &&
                                <div className={`${classes.w_100} ${classes.dFlex} ${classes.linkUnderline}`} style={{ marginTop: "20px", alignItems: "start", justifyContent: "center" }}>
                                    <small><b style={{ display: "block", marginTop: "3px" }} dangerouslySetInnerHTML={{ __html: serviceData.externalManagementText }}></b></small>
                                    <Checkbox style={{ paddingTop: 0 }} checked={externalManagement} onChange={(e) => setExternalManagement(e.target.checked)} />
                                </div>
                            }
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            <MuiDatePicker {...fieldProps} id="birth_date" name="birth_date" label="Birth Date" control={control} format="yyy-MM-dd" {...fieldError('birth_date', errors)} inputRef={register({ required: requiredErr })} required />
                            <AsyncAutocomplete {...fieldProps} isGeoNames id="birth_city" name="birth_city" label="City" control={control} rules={{ required: requiredErr }} {...fieldError('birth_city', errors)} required />
                            <DepField {...fieldProps} id="birth_state" name="birth_state" label='State' control={control} inputRef={register} {...fieldError('birth_state', errors)} parentfield='birth_city' parentprop="adminName1" />
                            <DepField {...fieldProps} id="birth_country" name="birth_country" label='Country' control={control} inputRef={register} {...fieldError('birth_country', errors)} parentfield='birth_city' parentprop="countryName" />
                            <AsyncAutocomplete {...fieldProps} isSearch id="nationality_country_code" name="nationality_country_code" label="Nationality Country" endpoint="nazioni" control={control} rules={{ required: requiredErr }} {...fieldError('nationality_country_code', errors)} required />
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            {fix}{fix}
                            <TextField {...fieldProps} id="residence_address" name="residence_address" label='Address' inputRef={register({ required: requiredErr })} {...fieldError('residence_address', errors)} required />
                            <TextField {...fieldProps} id="residence_zip" name="residence_zip" label='Postal Code' inputRef={register({ required: requiredErr })} {...fieldError('residence_zip', errors)} required /*type="number"*/ />
                            <AsyncAutocomplete {...fieldProps} isGeoNames id="residence_city" name="residence_city" label="City" control={control} rules={{ required: requiredErr }} {...fieldError('residence_city', errors)} required />
                            <DepField {...fieldProps} id="residence_state" name="residence_state" label='State' control={control} inputRef={register} {...fieldError('residence_state', errors)} parentfield='residence_city' parentprop="adminName1" />
                            <DepField {...fieldProps} id="residence_country" name="residence_country" label='Country' control={control} inputRef={register} {...fieldError('residence_country', errors)} parentfield='residence_city' parentprop="countryName" />
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            {fix}
                            <TextField {...fieldProps} id="passport_number" name="passport_number" label='Passport Number' inputRef={register({ required: requiredErr })} {...fieldError('passport_number', errors)} required />
                            <MuiDatePicker {...fieldProps} id="passport_start" name="passport_start" label="Issue Date" control={control} format="yyy-MM-dd" {...fieldError('passport_start', errors)} inputRef={register({ required: requiredErr })} required />
                            <MuiDatePicker {...fieldProps} id="passport_end" name="passport_end" label="Expiration Date" control={control} format="yyy-MM-dd" {...fieldError('passport_end', errors)} inputRef={register({ required: requiredErr })} required />
                            <AsyncAutocomplete {...fieldProps} isSearch id="passport_country_short" name="passport_country_short" label="Issue Country" endpoint="nazioni" control={control} rules={{ required: requiredErr }} {...fieldError('passport_country_short', errors)} required />
                            {/* <TextField {...fieldProps} id="passport_authority" name="passport_authority" label='Issuing Authority' inputRef={register({ required: requiredErr })} {...fieldError('passport_authority', errors)} required /> */}
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className={classes.stepperBox}>
                        {!isWidthDown('md', props.width) && <StepperHeader />}
                        <h4 className={classes.w_100}>{steps[step]}</h4>
                        <div className={classes.stepFields}>
                            {fix}
                            <AsyncAutocomplete {...fieldProps} id="visa" name="visa" label="Type" rules={{ required: requiredErr }} {...fieldError('visa', errors)} endpoint="studentvisas" control={control} required />
                            <TextField {...fieldProps} disabled={!visaRequired} InputProps={{ startAdornment: <InputAdornment position="start">Days</InputAdornment> }} id="visa_days" name="visa_days" label='Days of Validity' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_days', errors)} required={visaRequired} type="number" />
                            <TextField {...fieldProps} id="visa_number" name="visa_number" label='Number' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_number', errors)} required={visaRequired} type="number" />
                            <TextField {...fieldProps} id="visa_consulate" name="visa_consulate" label='Consulate' inputRef={register({ required: visaRequired ? requiredErr : false })} {...fieldError('visa_consulate', errors)} required={visaRequired} />
                            <MuiDatePicker {...fieldProps} id="visa_start" name="visa_start" label="Start Date" control={control} format="yyy-MM-dd" {...fieldError('visa_start', errors)} inputRef={register({ required: visaRequired ? requiredErr : false })} required={visaRequired} />
                            <MuiDatePicker {...fieldProps} id="visa_end" name="visa_end" label="Expiration Date" control={control} format="yyy-MM-dd" {...fieldError('visa_end', errors)} inputRef={register({ required: visaRequired ? requiredErr : false })} required={visaRequired} />
                        </div>
                    </div>
                )
            default: return 'Unknown step';
        }
    }

    return (
        <div className={classes.w_100}>
            {submitCompleted ? (
                <div className={classes.dFlex} style={{ height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <CardMedia className={classes.stepperLogo} image={logo} title="logo" style={{ width: "200px" }} />
                    <h1>Your request has been submitted!</h1>
                    {!externalManagement && onlinePayment && submitData?.id ? (
                        <>
                            <h3>Check your mailbox {getValues('email')}</h3>
                            <Container style={{ maxWidth: "900px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                {/* <NavLink to={`/payment/${submitData?.id}`}>
                            <Button type="submit" variant="contained" color="primary" size="medium" startIcon={<PaymentIcon />}>Pay Now</Button>
                            </NavLink> */}
                                <Typography style={{ marginBottom: "20px", background: theme.palette.secondary.main, borderRadius: "10px", padding: "20px 10px" }}>
                                    Please complete the payment by clicking on <b>“PAY NOW”</b> button below, otherwise the registration will not be successful. Thanks for your collaboration
                                </Typography>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<PaymentIcon />}
                                    // onClick={async () => {
                                    //     showLoader();
                                    //     await API.getRequest('payment', { student_id: submitData.id })
                                    //     hideLoader();
                                    // }}
                                    href={`${API.api}payment?student_id=${submitData.id}`}
                                // target="_blank"
                                >
                                    Pay Now
                                </Button>
                            </Container>
                        </>
                    ) : <></>}
                </div>
            ) : (validUrl ? (
                <form id='studentForm' autoComplete='off'>
                    {isWidthDown('md', props.width) && <StepperHeader />}
                    {
                        isWidthDown('md', props.width)
                            ? <VerticalStepper wrapClass="studentFormStepper" getSteps={getSteps} getStepContent={getStepContent} changeStep={changeStep} onFinish={onStudentSubmit} isSubmitted={isSubmitted} />
                            : <HorizontalStepper wrapClass="studentFormStepper" getSteps={getSteps} getStepContent={getStepContent} changeStep={changeStep} onFinish={onStudentSubmit} isSubmitted={isSubmitted} />
                    }
                </form>
            ) : notFound)}
        </div>
    );
}

export default withRouter(withWidth()(StudentForm));